<template>
    <Activity></Activity>
</template>
<script>
import Activity from './elements/ActivityPackage.vue';
export default {
    components:{
        Activity,
    }
}
</script>
<style>
    
</style>