<template>
    <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
        <!--begin::Title-->
        
        <h3 v-if="!(choosenVideo === video)" class="fs-5 text-dark text-hover-primary fw-bold flex-grow-1">{{ video.title }}</h3>
        <!--end::Title-->
        <div  class="symbol-group symbol-hover flex-nowrap flex-grow-1 pe-2" >
            <input
                v-if=" choosenVideo === video"
                class="form-control form-control-solid"
                v-model="theVideoModel.title"
                type="text"
                
            />
        </div>
       

        <!--begin::Users-->
        <div v-if="!(choosenVideo === video)" class="symbol-group symbol-hover flex-nowrap flex-grow-1 pe-2" >
            <a target="_blank" :href="video.embedUrl">{{ video.embedUrl }}</a>
        </div>
        <div  class="symbol-group symbol-hover flex-nowrap flex-grow-1 pe-2" >
            <input
                v-if=" choosenVideo === video"
                class="form-control form-control-solid"
                v-model="theVideoModel.embedUrl"
                type="text"
                
            />
        </div>
        <!--end::Users-->

        <!--begin::Progress-->
        <div class="min-w-125px pe-2">
        <span class="badge badge-light-primary">4 dk</span>
        </div>
        <!--end::Progress-->

        <!--begin::Action-->
        <button  v-if="!(choosenVideo === video)" @click="editVideo(video)" class="btn btn-sm btn-light btn-active-light-primary">Düzenle</button>
        <button  v-if="(choosenVideo === video)" @click="saveVideo(video)" class="btn btn-sm btn-light btn-active-light-primary">Kaydet</button>
        <!--end::Action-->
    </div>
</template>
<script>
import { ref,computed  } from 'vue';
export default {
    props: {
        video: {           // Declare the property
            type: Object,
            default: () => ({}),  // Do not forget about default value
        },
    },
    setup(props, { emit }) {
        const theVideoModel = computed({  // Use computed to wrap the object
            get: () => props.video,
            set: (value) => emit('update:video', value),
        });
      const isVideoEditting = ref(false);
      const choosenVideo = ref();
      const editVideo = (video) =>{
          choosenVideo.value = video;
          isVideoEditting.value = true;
      }
      const saveVideo = (video) =>{
          choosenVideo.value = {};
          isVideoEditting.value = false;
      }

      return {
          theVideoModel,
          editVideo,
          choosenVideo,
          isVideoEditting,
          saveVideo
      }
    }
}
</script>
<style lang="">
    
</style>